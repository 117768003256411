import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Menu, CameraAlt } from "@mui/icons-material";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import AlessaLogo from "../../../assets/alessaLogo.svg";
import { useUserStore } from "../../../zustand";
import { Select, Button, Tooltip, ButtonTheme } from "..";
import { useEnvSwitcher } from "../../../contexts";
import {
  StyledNavbar,
  NavRightSideDiv,
  ProfilePicDiv,
  UserMenu,
  CameraBox,
} from "./style";

export const Navbar = ({
  handleMicrosoftLogout,
}: {
  handleMicrosoftLogout: (
    instance: IPublicClientApplication,
    logoutCallback: () => void
  ) => void;
}) => {
  const [openUserMenu, setOpenUserMenu] = useState(false);

  const { env, setEnv } = useEnvSwitcher();
  const { user, removeUser } = useUserStore((state) => state);

  const { instance } = useMsal();
  let navigate = useNavigate();

  const options = window.__RUNTIME_CONFIG__?.REACT_APP_OPTIONS?.split(",") ?? [
    "dev",
    "qa",
    "uat",
  ];

  const clearUserState = (): void => {
    setOpenUserMenu(false);
    removeUser();
    navigate("/");
  };

  const handleLogout = () => {
    handleMicrosoftLogout(instance, clearUserState);
  };
  return (
    <StyledNavbar data-testid="navbar">
      <Menu fontSize="large" style={{ cursor: "pointer" }} />
      <img
        src={AlessaLogo}
        style={{
          marginLeft: "1rem",
          cursor: "pointer",
        }}
        width="150px"
        alt="Alessa Logo"
        onClick={() => navigate("/")}
      />
      <p
        style={{
          marginLeft: "1rem",
          marginBottom: "0.5rem",
          fontSize: "0.8rem",
          fontWeight: "bold",
        }}
      >
        Version: {process.env.REACT_APP_VERSION}
        Environment: {env.toUpperCase()}
      </p>
      {user.isLoggedIn ? (
        <NavRightSideDiv>
          <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
            Welcome back, {user.username}
          </span>
          <ProfilePicDiv
            data-testid="open-user-menu"
            onClick={() => setOpenUserMenu(!openUserMenu)}
          />
          {openUserMenu && (
            <UserMenu data-testid="user-menu">
              <Tooltip />
              <ProfilePicDiv
                style={{ margin: "0", width: "50px", height: "50px" }}
              >
                <CameraBox>
                  <CameraAlt style={{ fontSize: "18px", color: "#707070" }} />
                </CameraBox>
              </ProfilePicDiv>
              <b style={{ marginTop: "0.3rem" }}>{user.username}</b>
              <span
                style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "0.8rem",
                  margin: "0.3rem 0",
                }}
              >
                {user.email}
              </span>

              <Button
                data-testid="logout-button"
                width="8rem"
                content="sign out"
                onClick={handleLogout}
                theme={ButtonTheme.Tertiary}
              />
            </UserMenu>
          )}
        </NavRightSideDiv>
      ) : (
        <NavRightSideDiv>
          <Select
            placeholder="Select environment"
            width="12rem"
            selected={{ label: env.toUpperCase(), value: env }}
            setSelected={(value: any) => {
              setEnv(value.value);
            }}
            options={options.map((opt: string, idx: number) => ({
              label: opt.toUpperCase(),
              value: opt,
            }))}
          />
        </NavRightSideDiv>
      )}
    </StyledNavbar>
  );
};
